import InlineReputationIcon from "@/components/help-center/custom-tags/InlineReputationIcon.vue";

export const inlineReputationIcon = {
  render: InlineReputationIcon,
  attributes: {
    reputation: {
      type: String,
    },
  },
};
