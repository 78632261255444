<template>
  <div class="not-prose inline-flex">
    <img
      :class="reputationColor"
      class="w-7 h-7 rounded-md mx-2"
      src="https://assets.towerhunt.com/site/Transparent_White_T0.png"
      alt="Tower Hunt logo"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps(["reputation"]);
const reputationColor = computed(() => {
  switch (props.reputation) {
    case "bronze":
      return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
    case "silver":
      return "bg-gradient-to-r from-slate-200 to-gray-400 text-gray-900 hover:to-gray-500";
    case "gold":
      return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
    default:
      return "text-white bg-gray-800 hover:bg-gray-700";
  }
});
</script>
